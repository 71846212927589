<template>
  <div>
    <div v-show="imageSelectorEnabled" class="image-selector-wrapper">
      <template>
        <div class="header">
          <BInput
            v-model="searchString"
            :placeholder="$t('search')"
            class="search-images"
            data-label="AddImageSearch"
            type="search"
            icon="search"
          />
          <button
            class="delete"
            data-label="CloseImgSelectorBtn"
            @click="imageSelectorEnabled = false"
          />
        </div>
        <div v-if="error" class="content-wrapper">
          <BMessage type="is-danger" has-icon icon-size="is-small">
            <h2 class="mb-3">
              {{ $t('error') }}
            </h2>
            <p>{{ $t('unexpected-error-info-message') }}</p>
          </BMessage>
        </div>
        <div v-else class="image-selector">
          <div v-for="image in displayedImages" :key="image.id" class="card">
            <a
              :style="'background-image: url(' + image.preview + ')'"
              class="card-image"
              @click="addImage(image)"
            />
            <div class="card-content">
              <div class="content">
                <a data-label="AddImgLink" @click="addImage(image)">
                  <ImageName :image="image" />
                </a>
                <div>
                  {{ new Date(parseInt(image.created)).toLocaleString() }}
                </div>
              </div>
            </div>
          </div>

          <button
            v-if="nbImagesDisplayed < filteredImages.length"
            class="button"
            data-label="MoreImgsBtn"
            @click="more()"
          >
            {{ $t('button-more') }}
          </button>

          <div class="space">
            &nbsp;
          </div>
        </div>
      </template>
    </div>

    <a
      v-tooltip="{ content: $t('add-image'), placement: 'left' }"
      class="image-selector-button"
      data-label="ShowImgSelectorLink"
      @click="imageSelectorEnabled = true"
    >
      <i class="fas fa-plus" />
    </a>
  </div>
</template>

<script>
import ImageName from '@/components/image/ImageName.vue';
import { getWildcardRegexp } from '@/utils/string-utils';

export default {
  name: 'ImageSelector',
  components: { ImageName },
  props: {
    images: { type: Array, default: () => [] },
  },
  data() {
    return {
      searchString: '',
      nbImagesDisplayed: 20,
      error: false,
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    imageSelectorEnabled: {
      get() {
        return this.$store.getters['currentProject/currentViewer']
          .imageSelector;
      },
      set(value) {
        this.$store.commit(this.viewerModule + 'setImageSelector', value);
      },
    },
    filteredImages() {
      // TODO: in backend
      let filtered = this.images;

      if (this.searchString) {
        const regexp = getWildcardRegexp(this.searchString);
        filtered = filtered.filter((image) =>
          regexp.test(image.instanceFilename)
        );
      }

      return filtered;
    },
    displayedImages() {
      return this.filteredImages.slice(0, this.nbImagesDisplayed);
    },
  },
  mounted() {
    this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
  },
  beforeDestroy() {
    this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
  },
  methods: {
    async addImage(image) {
      try {
        await image.fetch(); // refetch image to ensure we have latest version
        await this.$store.dispatch(this.viewerModule + 'addImage', image);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-add-viewer-image'),
        });
      }
    },

    more() {
      this.nbImagesDisplayed += 20;
    },

    toggle() {
      this.imageSelectorEnabled = !this.imageSelectorEnabled;
    },

    shortkeyHandler(key) {
      if (key === 'toggle-add-image') {
        this.toggle();
      }
    },
  },
};
</script>

<style scoped>
.image-selector-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  width: 100%;
  height: 20.5em;
  z-index: 150;
}

.header {
  padding: 0.75em;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.image-selector {
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  flex: 1;
}

.card {
  display: inline-block;
  min-width: 12em;
  flex: 0;
  box-sizing: border-box;
  margin: 0.75em;
}

.card-image {
  display: inline-block;
  width: 100%;
  height: 9em;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  border-bottom: 1px solid #ddd;
}

.card-content {
  padding: 0.75em;
  font-size: 0.8rem;
  overflow-wrap: break-word;
  overflow: hidden;
  height: 6.5em;
}

.space {
  margin-left: 0.5em;
}

.image-selector-button {
  background: #95b5db;
  border: 0.35rem solid white;
  position: absolute;
  bottom: 4.5rem;
  right: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.8rem;
  color: white;
  font-size: 1.8rem;
  box-sizing: border-box;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  z-index: 100;
}
</style>
