<template>
  <div>
    <h2 class="text-center mb-2">
      {{ $t('terms') }}
    </h2>
    <div v-if="terms.length">
      <div class="flex justify-right sticky border-b border-grey-2 pb-2 top-0">
        <!-- border: 2px solid #dbdbdb; -->
        <div class="px-4 flex-grow">
          <BInput
            v-model="searchString"
            :label="$t('search')"
            :placeholder="$t('search')"
            size="is-small"
            expanded
          />
        </div>

        <div class="flex align-center pr-2">
          <div class="flex justify-center h-auto">
            <i class="far fa-eye p-1" />
            <BCheckbox
              v-model="isAllTermsChecked"
              size="is-small"
              :disabled="terms.length === 0"
            />
          </div>
          <div class="block w-96 text-center">
            {{ $t('opacity') }}
          </div>
        </div>
      </div>
      <div class="overflow-auto mb-1 max-h-240">
        <OntologyTree
          :ontology="ontology"
          :allow-selection="false"
          :allow-drag="true"
          :search-string="searchString"
          @termsChanged="onTermsChanged"
        >
          <template #custom-title="{term}">
            <CytomineTerm :term="term" />
            <span v-if="isDefaultTerm(term.id)" class="color-red">*</span>
          </template>
          <template #custom-sidebar="{ term }">
            <div class="flex align-center pr-2">
              <div class="flex pr-3">
                <span
                  v-if="
                    getHotkeyByTermId(term.id) &&
                      assigningHotkeyToTermId !== term.id
                  "
                  class="mr-2"
                  style="font-size:0.8em;background-color:#cecece;padding:2px 6px;border-radius:2px;"
                >
                  {{ getHotkeyByTermId(term.id) }}
                </span>
                <span v-if="assigningHotkeyToTermId === term.id">
                  {{ $t('listening-input') }}
                </span>

                <IdxBtn
                  v-if="
                    !getHotkeyByTermId(term.id) &&
                      assigningHotkeyToTermId !== term.id
                  "
                  link
                  @click="assigningHotkeyToTermId = term.id"
                >
                  <i class="fas fa-key" style="font-size:0.8em;" />
                </IdxBtn>
                <IdxBtn
                  v-if="
                    getHotkeyByTermId(term.id) &&
                      assigningHotkeyToTermId !== term.id
                  "
                  link
                  @click="removeHotkey(term.id)"
                >
                  <i
                    class="fas fa-times-circle color-red"
                    style="font-size:0.8em;"
                  />
                </IdxBtn>
              </div>
              <div class="visibility flex justify-center">
                <BCheckbox
                  v-if="term.id"
                  :value="terms[termsMapping[term.id]].visible"
                  size="is-small"
                  @input="toggleTermVisibility(termsMapping[term.id])"
                />

                <BCheckbox v-else v-model="displayNoTerm" size="is-small" />
              </div>

              <div class="flex w-96">
                <input
                  v-if="term.id"
                  :disabled="!terms[termsMapping[term.id]].visible"
                  :value="terms[termsMapping[term.id]].opacity"
                  class="slider m-0 is-small"
                  step="0.05"
                  min="0"
                  max="1"
                  type="range"
                  aria-label="Opacity"
                  @change="
                    (event) => changeOpacity(termsMapping[term.id], event)
                  "
                  @input="
                    (event) => changeOpacity(termsMapping[term.id], event)
                  "
                />

                <input
                  v-else
                  v-model="noTermOpacity"
                  class="slider m-0 is-small"
                  step="0.05"
                  min="0"
                  max="1"
                  type="range"
                  aria-label="No opacity"
                />
              </div>
            </div>
          </template>
        </OntologyTree>
      </div>
      <div class="has-text-right">
        <span style="font-size:0.8em; float:left;" class="mt-2"
          ><span class="color-red">*</span> =
          {{ $t('visible-by-default') }}</span
        >
        <button class="button is-small" @click="resetOpacities">
          {{ $t('button-reset-term-opacities') }}
        </button>
      </div>
    </div>
    <div v-else>
      <div class="flex justify-center align-center italic h-240 my-5">
        {{ $t('no-terms') }}
      </div>
    </div>
    <div class="opacity flex align-center">
      <label for="layers-opacity" class="w-160" style="font-size:1em;">{{
        $t('global-opacity')
      }}</label>
      <input
        id="layers-opacity"
        v-model="globalOpacity"
        class="slider is-fullwidth is-small"
        step="0.05"
        min="0"
        max="1"
        type="range"
      />
    </div>
  </div>
</template>

<script>
import OntologyTree from '@/components/ontology/OntologyTree.vue';
import CytomineTerm from '@/components/ontology/CytomineTerm.vue';

export default {
  name: 'OntologyPanel',
  components: { OntologyTree, CytomineTerm },
  props: {
    index: { type: String, required: true },
    defaultTermIds: { type: Array, required: false },
  },
  data() {
    return {
      searchString: '',
      isSelectedTermsUpdated: false,
      selectedTerms: [],
      visibleTerms: [],
      assigningHotkeyToTermId: '',
    };
  },
  computed: {
    /** @returns {object} */
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    /** @returns {string} */
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    /** @returns {object} */
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    /** @returns {object} */
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    /** @returns {boolean} */
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
    /** @returns {object} */
    customHotkeys() {
      return this.$store.getters[this.viewerModule + 'getCustomHotkeys'];
    },
    /** @returns {Array} */
    layers() {
      return this.imageWrapper.layers.selectedLayers || [];
    },
    /** @returns {boolean} */
    noActiveLayer() {
      return !this.layers.find((layer) => layer.drawOn);
    },
    /** @returns {object} */
    terms() {
      return this.imageWrapper.style.terms || [];
    },
    /** @returns {object} */
    termsMapping() {
      const mapping = {};
      this.terms.forEach((term, idx) => (mapping[term.id] = idx));
      return mapping;
    },
    globalOpacity: {
      get() {
        return this.imageWrapper.style.globalOpacity;
      },
      set(value) {
        this.$store.commit(
          this.imageModule + 'setGlobalOpacity',
          Number(value)
        );
      },
    },
    /** @returns {object[]} */
    additionalNodes() {
      return [
        {
          id: 0,
          name: this.$t('no-term'),
        },
      ];
    },
    displayNoTerm: {
      /** @returns {boolean} */
      get() {
        return this.imageWrapper.style.displayNoTerm;
      },
      /** @param {boolean} value */
      set(value) {
        this.$store.dispatch(this.imageModule + 'setDisplayNoTerm', value);
      },
    },
    noTermOpacity: {
      /** @returns {number} */
      get() {
        return this.imageWrapper.style.noTermOpacity;
      },
      /** @param {number} value */
      set(value) {
        this.$store.commit(
          this.imageModule + 'setNoTermOpacity',
          Number(value)
        );
      },
    },
    isAllTermsChecked: {
      get() {
        /** @returns {boolean} */
        return this.visibleTerms.every(
          (term) => this.terms[this.termsMapping[term.id]].visible
        );
      },
      set(isVisible) {
        for (const term of this.visibleTerms) {
          this.$store.dispatch(this.imageModule + 'toggleTermVisibility', [
            this.termsMapping[term.id],
            isVisible,
          ]);
        }
        this.isSelectedTermsUpdated = true;
      },
    },
  },
  mounted() {
    this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
    this.visibleTerms = this.terms;
  },
  beforeDestroy() {
    this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
  },
  methods: {
    toggleTermVisibility(index) {
      this.$store.dispatch(this.imageModule + 'toggleTermVisibility', [index]);
      this.isSelectedTermsUpdated = true;
    },
    toggleSelectedTermsVisibility(selectedTerms) {
      for (const term of selectedTerms) {
        this.$store.dispatch(this.imageModule + 'toggleTermVisibility', [
          this.termsMapping[term.id],
        ]);
      }
    },
    changeOpacity(index, event) {
      const opacity = Number(event.target.value);
      this.$store.commit(this.imageModule + 'setTermOpacity', {
        indexTerm: index,
        opacity,
      });
    },
    resetOpacities() {
      this.$store.commit(this.imageModule + 'resetTermOpacities');
    },
    isDefaultTerm(termId) {
      return this.defaultTermIds.includes(termId);
    },
    removeHotkey(termId) {
      this.$store.dispatch(this.viewerModule + 'assignCustomHotkey', {
        key: 'term-' + this.getHotkeyByTermId(termId),
        value: null,
      });
    },
    getHotkeyByTermId(termId) {
      if (!this.customHotkeys) {
        return null;
      }
      for (const key of Object.keys(this.customHotkeys)) {
        if (this.customHotkeys[key] === termId.toString()) {
          return key.replace('term-', '');
        }
      }
      return null;
    },
    async shortkeyHandler(key) {
      if (!this.isActiveImage) {
        // shortkey should only be applied to active map
        return;
      }

      if (this.terms?.length == 0) {
        // Error message no terms available
        this.$notify({
          type: 'error',
          text: this.$t('terms-not-available'),
        });
        return;
      }

      if (key === 'tool-toggle-terms-showing') {
        if (this.isSelectedTermsUpdated || this.selectedTerms?.length === 0) {
          this.selectedTerms = [];
          for (const term of this.terms) {
            if (this.terms[this.termsMapping[term.id]].visible) {
              this.selectedTerms.push(term);
            }
          }
          this.isSelectedTermsUpdated = false;
        }
        this.toggleSelectedTermsVisibility(this.selectedTerms);
      }
      if (
        [
          'term-0',
          'term-1',
          'term-2',
          'term-3',
          'term-4',
          'term-5',
          'term-6',
          'term-7',
          'term-8',
          'term-9',
        ].includes(key)
      ) {
        // assigning a hotkey or using a hotkey
        if (this.assigningHotkeyToTermId) {
          // assigning a hotkey
          this.$store.dispatch(this.viewerModule + 'assignCustomHotkey', {
            key,
            value: this.assigningHotkeyToTermId,
          });
          this.assigningHotkeyToTermId = '';
        } else if (
          !this.imageWrapper.selectedFeatures.selectedFeatures?.length // don't hide/show terms if there are selected features
        ) {
          // using a hotkey
          const termId = this.customHotkeys[key];
          if (termId) {
            this.toggleTermVisibility(this.termsMapping[termId]);
          } else {
            // TODO: No existing hotkey
            this.$notify({
              type: 'error',
              text: this.$t('hotkey-not-assigned'),
            });
          }
        }
      }
    },
    onTermsChanged(terms) {
      this.visibleTerms = terms;
    },
  },
};
</script>

<style scoped>
.visibility {
  width: 2.8em;
  height: 2.1em;
}

>>> .sl-vue-tree-title {
  align-items: center;
}

>>> .checkbox .control-label {
  padding: 0 !important;
}
</style>
