import WKT from 'ol/format/WKT';

export const getFeatureByAnnotation = (annotation) => {
  return {
    geometry: {
      coordinates: annotation.location
        ? new WKT()
            .readFeature(annotation.location)
            .getGeometry()
            .getCoordinates()
        : [],
      type: 'Polygon',
    },
    id: annotation.id,
    userId: annotation.user,
    properties: {
      annot: annotation,
      type: 'Feature',
    },
  };
};
