<template>
  <component
    :is="panel.component"
    :id="panel.key + '-panel'"
    :index="index"
    :style="panelStyle"
    :class="panelClass"
    v-bind="panel.params"
  />
</template>

<script>
export default {
  name: 'PanelContainer',
  props: {
    panel: Object,
    index: String,
    nbImages: Number,
    clientHeight: Number,
  },
  computed: {
    panelStyle() {
      return {
        right: '100%',
        maxHeight: `${this.clientHeight - 30}px`,
      };
    },
    panelClass() {
      return `${
        this.panel.fixedPosition ? 'panel-fixed' : 'panel-options'
      } absolute z-100 w-400 overflow-auto p-3 bg-gray-1 ${
        this.nbImages > 2 ? 'short' : ''
      }`;
    },
  },
};
</script>
