<template>
  <vl-layer-vector
    v-if="!heatmapMode"
    ref="olLayerVector"
    :visible="layer.visible"
    :extent="imageExtent"
    :update-while-interacting="false"
  >
    <vl-source-vector
      ref="olSource"
      :loader-factory="loaderFactory"
      :strategy-factory="strategyFactory"
      url="-"
    >
      <!-- HACK because loader factory not used if URL not specified -->
      <vl-style-func :factory="styleFunctionFactory" />
    </vl-source-vector>
  </vl-layer-vector>
  <vl-layer-heatmap v-else ref="heatmapSource">
    <vl-source-vector
      ref="olSource"
      :loader-factory="loaderFactory"
      :strategy-factory="strategyFactory"
      url="-"
    />
  </vl-layer-heatmap>
</template>

<script>
import WKT from 'ol/format/WKT';
import { Annotation } from 'cytomine-client';
import { getAnnotations } from '@/workers/index.js';
import {
  annotBelongsToLayer,
  transformWktLocation,
} from '@/utils/annotation-utils';
import { getFeatureByAnnotation } from '@/utils/ol/features';

const ANNOT_LOAD_SIZE = 3000; // batch size for annotation loading
const MAX_ANNOTATIONS_TO_LOAD = 30000; // max number of annotations to load at certain zoom levels

export default {
  name: 'AnnotationLayer',
  props: {
    index: String,
    layer: Object,
    heatmapMode: Boolean,
  },
  emits: ['setLoadingPercent'],
  data() {
    return {
      format: new WKT(),

      resolution: null,
      lastExtent: null,
      clustered: null,
      maxResolutionNoClusters: null,
      hasLoaded: false,
      refreshTimeout: null,
      loadedAnnotCount: 0,
      annotsToLoad: 0,
    };
  },
  computed: {
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    annotsIdsToSelect() {
      return this.imageWrapper.selectedFeatures.annotsToSelect.map(
        (annot) => annot.id
      );
    },
    imageExtent() {
      return [0, 0, this.image.width, this.image.height];
    },
    selectedFeatures() {
      return this.imageWrapper.selectedFeatures.selectedFeatures;
    },
    selectedTermsIds() {
      return this.terms.filter((term) => term.visible).map((term) => term.id);
    },
    ongoingEdit() {
      return this.imageWrapper.draw.ongoingEdit;
    },
    terms() {
      return this.imageWrapper.style.terms || [];
    },
    activeMirroring() {
      return this.imageWrapper.draw.activeMirroring;
    },
    clustering() {
      return this.imageWrapper.view.clustering;
    },
    selectedLayers() {
      // Array<User> (representing user layers)
      return this.imageWrapper.layers.selectedLayers || [];
    },
    styleFunctionFactory() {
      // Force computed property update when one of those properties change (leading to new style function =>
      // rerendering - see https://github.com/ghettovoice/vuelayers/issues/68#issuecomment-404223423)
      this.imageWrapper.selectedFeatures.selectedFeatures;
      this.imageWrapper.style.globalOpacity;
      this.terms.forEach((term) => {
        term.visible;
        term.opacity;
      });
      this.layer.opacity;
      this.imageWrapper.style.displayNoTerm;
      this.imageWrapper.style.noTermOpacity;
      this.imageWrapper.properties.selectedPropertyKey;
      this.imageWrapper.properties.selectedPropertyColor;
      this.imageWrapper.review.reviewMode;

      return () => {
        return this.$store.getters[this.imageModule + 'genStyleFunction'];
      };
    },
    reviewMode() {
      return this.imageWrapper.review.reviewMode;
    },
  },
  watch: {
    reviewMode() {
      // in review mode, reviewed annotation no longer displayed => need to force reload
      this.clearFeatures();
    },
    clustering() {
      this.reloadAnnotationsHandler();
    },
    selectedTermsIds() {
      if (this.heatmapMode) {
        this.reloadAnnotationsHandler();
      }
    },
    activeMirroring() {
      this.reloadAnnotationsHandler();
    },
    heatmapMode() {
      this.reloadAnnotationsHandler();
    },
  },
  async mounted() {
    this.$eventBus.$on('addAnnotations', this.addAnnotationsHandler);
    this.$eventBus.$on('selectAnnotation', this.selectAnnotationHandler);
    this.$eventBus.$on('selectAnnotations', this.selectAnnotationsHandler);
    this.$eventBus.$on('reloadAnnotations', this.reloadAnnotationsHandler);
    this.$eventBus.$on('reviewAnnotation', this.reviewAnnotationHandler);
    this.$eventBus.$on('editAnnotations', this.editAnnotationsHandler);
    this.$eventBus.$on('deleteAnnotations', this.deleteAnnotationsHandler);
    this.$eventBus.$on('mirrorAnnotation', this.mirroringHandler);
    this.$eventBus.$on('setHeatmapBlur', this.setHeatmapBlurHandler);
    this.$eventBus.$on('setHeatmapRadius', this.setHeatmapRadiusHandler);
  },
  beforeDestroy() {
    // unsubscribe from all events
    this.$eventBus.$off('addAnnotations', this.addAnnotationsHandler);
    this.$eventBus.$off('selectAnnotation', this.selectAnnotationHandler);
    this.$eventBus.$off('selectAnnotations', this.selectAnnotationsHandler);
    this.$eventBus.$off('reloadAnnotations', this.reloadAnnotationsHandler);
    this.$eventBus.$off('reviewAnnotation', this.reviewAnnotationHandler);
    this.$eventBus.$off('editAnnotations', this.editAnnotationsHandler);
    this.$eventBus.$off('deleteAnnotations', this.deleteAnnotationsHandler);
    this.$eventBus.$off('mirrorAnnotation', this.mirroringHandler);
    this.$eventBus.$off('setHeatmapBlur', this.setHeatmapBlurHandler);
    this.$eventBus.$off('setHeatmapRadius', this.setHeatmapRadiusHandler);
  },
  methods: {
    clearFeatures() {
      if (this.$refs.olSource) {
        this.$store.commit(
          this.imageModule + 'removeLayerFromSelectedFeatures',
          {
            layer: this.layer,
            cache: true,
          }
        );
        this.$refs.olSource.clearFeatures();
      }
    },

    annotBelongsToLayer(annot) {
      return annotBelongsToLayer(annot, this.layer, this.image);
    },

    addAnnotationsHandler(annots) {
      if (this.$refs.olSource) {
        const currentLayerAnnots = annots.filter((annot) =>
          this.annotBelongsToLayer(annot)
        );
        const featuresToAdd = currentLayerAnnots.map((annot) =>
          this.createFeature(annot)
        );
        this.$refs.olSource.addFeatures(featuresToAdd);
      }
    },
    selectAnnotationHandler({ annot, index }) {
      if (
        index === this.index &&
        this.annotBelongsToLayer(annot) &&
        this.$refs.olSource
      ) {
        const olFeature = this.$refs.olSource.getFeatureById(annot.id);
        if (!olFeature) {
          this.$store.commit(this.imageModule + 'setAnnotToSelect', annot);
        } else {
          this.$store.dispatch(this.imageModule + 'selectFeature', olFeature);
        }
      }
    },

    // Compares passed annotations to those currently loaded in the layer and selects only the loaded annotations.
    selectAnnotationsHandler({ annots, index }) {
      if (index === this.index && this.$refs.olSource) {
        const featuresToSelect = [];
        const sourceFeatures = this.$refs.olSource.getFeatures();
        const annotDict = annots.reduce((obj, annot) => {
          obj[annot.id] = annot;
          return obj;
        }, {});

        for (const feature of sourceFeatures) {
          if (annotDict[feature.id_]) {
            featuresToSelect.push(
              getFeatureByAnnotation(annotDict[feature.id_])
            );
          }
        }

        // save selected features
        this.$store.commit(
          this.imageModule + 'addSelectedFeatures',
          featuresToSelect
        );

        // show info modal
        if (featuresToSelect.length) {
          this.$store.commit(this.imageModule + 'setDisplayAnnotDetails', true);
        }
      }
    },
    async reloadAnnotationsHandler({
      idImage,
      clear = false,
      callback = null,
    } = {}) {
      if (!idImage || idImage === this.image.id) {
        if (clear) {
          this.clearFeatures();
        } else {
          await this.loader();
        }
      }
      if (callback) {
        callback();
      }
    },
    reviewAnnotationHandler(annot) {
      if (this.reviewMode) {
        // if the image is in review mode, reviewed annotation should no longer be displayed on user layer => call delete handler
        this.deleteAnnotationsHandler(annot);
      }
    },
    editAnnotationsHandler(annots, updateSelectedFeatures = true) {
      annots.forEach((annot) => {
        if (this.annotBelongsToLayer(annot) && this.$refs.olSource) {
          const olFeature = this.$refs.olSource.getFeatureById(annot.id);
          if (!olFeature) {
            return;
          }
          if (annot.location) {
            if (this.activeMirroring) {
              // Convert location from database location to image location
              // Currently only used to support mirroring
              annot.location = transformWktLocation(
                annot.location,
                this.activeMirroring,
                this.image
              );
            }
            olFeature.setGeometry(this.format.readGeometry(annot.location));
          }

          olFeature.set('annot', annot);

          if (updateSelectedFeatures) {
            const indexSelectedFeature = this.selectedFeatures.findIndex(
              (ftr) => ftr.id === annot.id
            );
            if (indexSelectedFeature >= 0) {
              this.$store.commit(
                this.imageModule + 'changeAnnotSelectedFeature',
                {
                  indexFeature: indexSelectedFeature,
                  annot,
                }
              );
            }
          }
        }
      });
    },
    deleteAnnotationsHandler(annots) {
      let clearSelectedFeatures = false;
      annots.forEach((annot) => {
        if (this.annotBelongsToLayer(annot) && this.$refs.olSource) {
          const olFeature = this.$refs.olSource.getFeatureById(annot.id);

          if (!olFeature) return;

          this.$refs.olSource.removeFeature(olFeature);

          if (this.selectedFeatures.some((ftr) => ftr.id === annot.id)) {
            clearSelectedFeatures = true;
          }
        }
      });

      if (clearSelectedFeatures) {
        this.$store.commit(this.imageModule + 'clearSelectedFeatures');
      }
    },
    mirroringHandler() {
      this.clearFeatures();
    },

    strategyFactory() {
      return (extent, resolution) => {
        const hasExtentChanged = this.hasExtentChanged(extent);
        this.lastExtent = extent;

        if (
          this.$refs.olSource &&
          this.resolution &&
          this.clustered != null && // some features have already been loaded
          ((!this.clustered && resolution > this.maxResolutionNoClusters) || // recluster
            (resolution !== this.resolution && this.clustered))
        ) {
          // change of resolution while clustering
          // clear loaded extents to force reloading features
          this.$refs.olSource.$source.loadedExtentsRtree_.clear();
        }

        if (hasExtentChanged) {
          if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
          }

          this.$emit('setLoadingPercent', 1, this.layer.id);
          this.refreshTimeout = setTimeout(() => {
            this.reloadAnnotationsHandler();
          }, 1000);
        }
        return [extent];
      };
    },

    async fetchAnnots(extent, offset = 0) {
      const adaptedExtent = [...extent];
      [0, 1].forEach((index) => {
        if (adaptedExtent[index] < 0) {
          adaptedExtent[index] = 0;
        }
      });
      [2, 3].forEach((index) => {
        if (this.imageExtent[index] < adaptedExtent[index]) {
          adaptedExtent[index] = this.imageExtent[index];
        }
      });

      if (this.activeMirroring == 'horizontal') {
        var tmp = extent[0];
        adaptedExtent[0] = adaptedExtent[2];
        adaptedExtent[2] = tmp;
        adaptedExtent[0] = this.imageExtent[0] - adaptedExtent[0];
        adaptedExtent[2] = this.imageExtent[2] - adaptedExtent[2];
      }
      if (this.activeMirroring == 'vertical') {
        var tmp = adaptedExtent[1];
        adaptedExtent[1] = adaptedExtent[3];
        adaptedExtent[3] = tmp;
        adaptedExtent[1] = this.imageExtent[1] - adaptedExtent[1];
        adaptedExtent[3] = this.imageExtent[3] - adaptedExtent[3];
      }

      const annots = await getAnnotations({
        user: !this.layer.isReview ? this.layer.id : null,
        image: this.image.id,
        reviewed: this.layer.isReview,
        notReviewedOnly: !this.layer.isReview && this.reviewMode,
        kmeans: false,
        bbox: adaptedExtent.join(),
        showWKT: true,
        showTerm: true,
        showGIS: true,
        displacement: offset,
        limit: ANNOT_LOAD_SIZE,
        counting: true,
      });

      let annotsArray = annots.collection;
      annotsArray = annotsArray.map((annot) => new Annotation({ ...annot }));
      // terms visibility cannot be handled by stylefunc in heatmap mode
      // this is to filter out hidden terms manually
      if (
        this.heatmapMode &&
        this.selectedTermsIds.length !== this.terms.length
      ) {
        annotsArray = annotsArray.filter(
          (annot) =>
            !annot.term ||
            annot.term.length === 0 ||
            annot.term.some((termId) => this.selectedTermsIds.includes(termId))
        );
      }

      return annotsArray;
    },

    updateFeature(feature, annot) {
      const indexSelectedFeature = this.selectedFeatures.findIndex(
        (ftr) => ftr.id === feature.getId()
      );
      const isFeatureSelected = indexSelectedFeature !== -1;

      if (!annot) {
        console.log(
          `Removing annot ${feature.getId()} in layer ${
            this.layer.id
          } (external action)`
        );
        this.$refs.olSource.removeFeature(feature);
        if (isFeatureSelected) {
          this.$store.commit(this.imageModule + 'clearSelectedFeatures');
        }
        return;
      }

      const storedAnnot = feature.get('annot');
      const numStoredAnnotIndicies = storedAnnot.location.split(',').length;
      const numAnnotIndicies = annot.location.split(',').length;

      if (
        !this.clustered &&
        annot.updated === storedAnnot.updated &&
        numStoredAnnotIndicies === numAnnotIndicies &&
        this.sameTerms(annot.term, storedAnnot.term)
      ) {
        // no modification performed since feature was loaded
        return;
      }

      if (isFeatureSelected) {
        if (this.ongoingEdit) {
          // if feature is selected and under modification, updating it may lead to conflict
          console.log(
            `Skipping update of selected annot ${annot.id} in layer ${this.layer.id} (ongoing edit)`
          );
          return;
        }
        console.log(
          `Updating selected annot ${annot.id} in layer ${this.layer.id} (external action)`
        );
        this.$store.commit(this.imageModule + 'changeAnnotSelectedFeature', {
          indexFeature: indexSelectedFeature,
          annot,
        });
      }

      console.log(
        `Updating annot ${annot.id} in layer ${this.layer.id} (external action)`
      );
      feature.set('annot', annot);
      feature.setGeometry(this.format.readGeometry(annot.location));
    },

    async loadAnnotations(extent, resolution, offset) {
      let arrayAnnots = [];
      try {
        arrayAnnots = await this.fetchAnnots(extent, offset);
        // Order by size, so bigger ones are always sent to back
        this.totalAnnotsCount = arrayAnnots.length && arrayAnnots[0].counting;
        this.annotsToLoad = this.clustering
          ? Math.min(MAX_ANNOTATIONS_TO_LOAD, this.totalAnnotsCount)
          : this.totalAnnotsCount;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-fetch-annotations-viewer'),
        });
        return;
      }

      if (!this.$refs.olSource) {
        return;
      }

      const wasClustered = this.clustered;
      if (arrayAnnots.length) {
        this.clustered = arrayAnnots[0].count != null;
        if (!this.clustered && resolution > this.maxResolutionNoClusters) {
          this.maxResolutionNoClusters = resolution;
        }
      }

      // Re-enable if annotations are ever updated individually again
      // this was disabled when annotations were cleared and re-added on every fetch
      // this was to prevent too many annotations rendered at once which causes JS heap overload

      // const annots = arrayAnnots.reduce((obj, annot) => {
      //   obj[annot.id] = annot;
      //   return obj;
      // }, {});
      const seenAnnots = [];

      if (wasClustered !== null && wasClustered !== this.clustered) {
        this.clearFeatures(); // clearing features will retrigger the loader
      } else {
        // const features = this.clustered
        //   ? this.$refs.olSource.$source.getFeatures()
        //   : this.$refs.olSource.$source.getFeaturesInExtent(extent);
        // features.forEach((feature) => {
        //   this.updateFeature(feature, annots[feature.getId()]);
        //   seenAnnots.push(feature.getId());
        // });
      }

      const annotsToAdd = [];
      arrayAnnots.forEach((annot) => {
        if (seenAnnots.includes(annot.id)) return;
        annotsToAdd.push(this.createFeature(annot));
      });

      // before we add the new features and clear old ones, check if extent has changed
      if (this.hasExtentChanged(extent)) {
        console.log('extent has changed, aborting load');
        return false;
      }
      if (offset === 0) {
        this.$refs.olSource.clearFeatures();
      }
      this.$refs.olSource.addFeatures(annotsToAdd);
      return annotsToAdd;
    },

    async loader(extent = [...this.lastExtent], resolution = this.resolution) {
      this.resolution = resolution;

      if (!this.layer.visible || !extent) {
        return;
      }

      let completed = true;
      this.annotsToLoad = 0;
      this.$emit('setLoadingPercent', 1, this.layer.id);

      try {
        // intial load of annotations
        let loadedAnnots = await this.loadAnnotations(
          extent,
          resolution,
          this.loadedAnnotCount
        );
        this.loadedAnnotCount = loadedAnnots.length;

        // load annotations until all are loaded or extent changes
        while (this.loadedAnnotCount < this.annotsToLoad) {
          this.$emit(
            'setLoadingPercent',
            Math.round((this.loadedAnnotCount / this.annotsToLoad) * 100),
            this.layer.id
          );

          if (this.hasExtentChanged(extent)) {
            console.log('extent has changed, aborting load');
            completed = false;
            break;
          }
          loadedAnnots = await this.loadAnnotations(
            extent,
            resolution,
            this.loadedAnnotCount
          );

          this.loadedAnnotCount += loadedAnnots.length;
        }

        if (completed) {
          this.$emit('setLoadingPercent', 0, this.layer.id);
          this.$store.dispatch(this.imageModule + 'setAnnotationCounts', [
            this.selectedLayers.indexOf(this.layer),
            {
              loaded: this.loadedAnnotCount,
              total: this.totalAnnotsCount,
            },
          ]);
          this.annotsToLoad = 0;
          this.loadedAnnotCount = 0;
        }

        return completed && loadedAnnots;
      } catch (error) {
        console.error(error);
        this.$emit('setLoadingPercent', 0, this.layer.id);
        return false;
      }
    },

    hasExtentChanged(oldExtent) {
      return (
        !this.lastExtent ||
        oldExtent[0] !== this.lastExtent[0] ||
        oldExtent[1] !== this.lastExtent[1] ||
        oldExtent[2] !== this.lastExtent[2] ||
        oldExtent[3] !== this.lastExtent[3]
      );
    },

    loaderFactory() {
      return (extent, resolution) => {
        // this should be run on initial load only
        // for some reason this will fire multiple times when fully zoomed out and panning/rotating
        // NOTE: for updates when panning/zooming, use strategy factory
        if (!this.hasLoaded) {
          this.loader(extent, resolution);
          this.hasLoaded = true;
        }
      };
    },

    createFeature(annot) {
      if (this.heatmapMode) {
        annot.location = `POINT(${annot.x} ${annot.y})`; // Convert all annotations to POINTS. VueLayers heatmap only takes points.
      }
      if (this.activeMirroring) {
        // Convert location from database location to image location
        // Currently only used to support mirroring
        annot.location = transformWktLocation(
          annot.location,
          this.activeMirroring,
          this.image
        );
      }
      const feature = new WKT().readFeature(annot.location);
      feature.setId(annot.id);
      feature.set('annot', annot);

      if (this.annotsIdsToSelect.includes(annot.id)) {
        this.$store.dispatch(this.imageModule + 'selectFeature', feature);
      }

      return feature;
    },

    sameTerms(terms1, terms2) {
      if (terms1.length !== terms2.length) {
        return false;
      }
      return terms1.every((term) => terms2.includes(term));
    },

    setHeatmapBlurHandler(value) {
      this.$refs.heatmapSource.$olObject.setBlur(value);
    },

    setHeatmapRadiusHandler(value) {
      this.$refs.heatmapSource.$olObject.setRadius(value);
    },
  },
};
</script>
