import http from '../../../../../utils/http.js';
import noteApi from '../../../../../services/noteApi.js';

export default {
  state() {
    return {
      channelGroups: [],
      activeGroup: {
        colorMaps: [],
      },
      defaultGroup: {
        id: null,
        groupName: 'Default',
        colorMaps: [],
      },
      successfulCall: false,
      canManipulate: false,
    };
  },
  getters: {
    channels: (state) => state.activeGroup.colorMaps,
    canManipulate: (state) => state.canManipulate,
  },
  mutations: {
    deleteChannelGroup(state) {
      const index = state.channelGroups.findIndex(
        (a) => a.id === state.activeGroup.id
      );
      state.channelGroups.splice(index, 1);
    },
    deleteChannel(state, data) {
      const index = state.activeGroup.colorMaps.findIndex((a) => {
        a.id === data.id;
      });
      state.activeGroup.colorMaps.splice(index, 1);
    },
    setCanManipulate(state, boolean) {
      state.canManipulate = boolean;
    },
    setChannelGroups(state, channelGroups) {
      state.channelGroups = channelGroups;
    },
    setActiveGroup(state, channelGroup) {
      state.activeGroup = channelGroup;
      localStorage.setItem('lastActiveGroupName', channelGroup?.groupName);
    },
    setDefaultGroup(state, defaultGroup) {
      state.defaultGroup = defaultGroup;
    },
    setChannelName(state, data) {
      state.activeGroup.colorMaps = state.activeGroup.colorMaps.map(
        (channel) => {
          if (channel.id === data.id) {
            return {
              ...channel,
              columnName: data.columnName,
              name: data.name,
              updated: data.updated,
              version: data.version,
            };
          }
          return channel;
        }
      );
    },
    setChannelColor(state, data) {
      state.activeGroup.colorMaps = state.activeGroup.colorMaps.map(
        (channel) => {
          if (channel.id === data.id) {
            return {
              ...channel,
              color: data.color,
            };
          }
          return channel;
        }
      );
    },
    setChannelMin(state, { channelId, min }) {
      state.activeGroup.colorMaps = state.activeGroup.colorMaps.map(
        (channel) => {
          if (channel.id === channelId) {
            return {
              ...channel,
              min: min,
            };
          }
          return channel;
        }
      );
    },
    setChannelMax(state, { channelId, max }) {
      state.activeGroup.colorMaps = state.activeGroup.colorMaps.map(
        (channel) => {
          if (channel.id === channelId) {
            return {
              ...channel,
              max: max,
            };
          }
          return channel;
        }
      );
    },
    setChannelGamma(state, { channelId, gamma }) {
      state.activeGroup.colorMaps = state.activeGroup.colorMaps.map(
        (channel) => {
          if (channel.id === channelId) {
            return {
              ...channel,
              gamma: gamma,
            };
          }
          return channel;
        }
      );
    },
    setChannelVisibility(state, data) {
      state.activeGroup.colorMaps = state.activeGroup.colorMaps.map(
        (channel) => {
          if (channel.id === data.id) {
            return {
              ...channel,
              visible: data.visible,
            };
          }
          return channel;
        }
      );
    },
    setChannelHistEqualization(state, data) {
      state.activeGroup.colorMaps = state.activeGroup.colorMaps.map(
        (channel) => {
          if (channel.id === data.id) {
            return {
              ...channel,
              histEqualization: data.histEqualization,
            };
          }
          return channel;
        }
      );
    },
    setSuccessfulCall(state, response) {
      state.successfulCall = response;
    },
  },
  actions: {
    async getDefaultChannels({ commit, state }, image) {
      const urls = await image.imageServerURLs.map((url) => {
        url = url.replace('tile', 'coli');
        url = url.replace('zoomify', 'path');
        url = url.slice(0, -1);
        return url;
      });
      const response = await http.get(urls[0]);
      if (
        response.data[0].name != 'red' ||
        response.data[1].name != 'green' ||
        response.data[2].name != 'blue'
      ) {
        commit('setCanManipulate', true);
      }
      const channelGroup = {
        ...state.defaultGroup,
        colorMaps: response.data.map((channel, ind) => {
          const color = {
            r: channel.value[0],
            g: channel.value[1],
            b: channel.value[2],
          };
          return {
            ...channel,
            columnName: channel.name,
            visible: true,
            histEqualization: false,
            id: ind,
            color: color,
            min: channel.value[3] || 0,
            max: channel.value[4] || 255,
            gamma: channel.value[5] || 1,
          };
        }),
      };
      commit('setDefaultGroup', channelGroup);
    },
    async saveToProject({ state, commit }, projectId) {
      const res = await noteApi.put(`napi/project/${projectId}/colormap`, {
        json: {
          colorMaps: state.activeGroup.colorMaps,
        },
      });
      commit('setSuccessfulCall', res);
    },
    async saveToProjectAsNew({ state, commit }, { projectId, name, userId }) {
      const res = await noteApi.post(`napi/project/${projectId}/colormap`, {
        json: {
          colorMaps: state.activeGroup.colorMaps,
          name: name,
          userId: userId,
        },
      });

      if (res && res.id) {
        res.colorMaps = res.colorMaps.map((colorMap) => {
          return {
            ...colorMap,
            colorMapGroupId: res.id,
          };
        });
        commit('setChannelGroups', [...state.channelGroups, res]);
        commit('setActiveGroup', res);
        commit('setSuccessfulCall', true);
      } else {
        commit('setSuccessfulCall', false);
      }
    },

    async deleteChannelGroup({ state, commit }, { projectId }) {
      const res = await noteApi.delete(
        `napi/project/${projectId}/colormap/${state.activeGroup.id}`
      );
      if (res) {
        commit('deleteChannelGroup');
      }
      return res;
    },
  },
};
